<template>
  <v-menu :location="`bottom`" :aria-label="`language-${globals.getRandomString(10)}`">
    <template v-slot:activator="{ props }">
      <v-btn :title="$t('language-info')" v-bind="props" variant="text" class="box bg-transparent" gap="sm" valign="center" cursor="pointer" :fsize="fsize ? fsize : null">
        <Language
          :language="$i18n.locale"
          :width="flag"
          :height="flag"
          :key="$i18n.locale"
        />
        <span tshadow="xs" pdl="2xs">
          {{ $t('language') }}
        </span>
      </v-btn>
    </template>
    <v-list pd="xs" density="compact" :fsize="fsize ? fsize : null">
      <v-list flex="1" density="compact">
        <template v-for="(item, i) in languages" :key="i">
          <v-list-subheader v-if="item.type === 'subheader'" pdt="3xs" pdb="sm">
            <span color="xl">{{ item.title }}</span>
          </v-list-subheader>
          <hr v-else-if="item.type === 'divider'" class="hr" mg="sm-y" bg="5xs">
          <p v-else-if="item.type === 'block'" pd="xs" />
          <NuxtLink v-else :title="item.text" @click="item.event">
            <v-list-item
              :value="item"
              :bg="item.code === $i18n.locale ? `4xs` : ``"
              rounded="xl"
              flex="1"
            >
              <template v-slot:prepend>
                <div class="box" gap="xs" valign="center">
                  <Language
                    :language="item.code"
                    :width="20"
                  />
                  <span>
                    {{ item.text }}
                  </span>
                </div>
              </template>
            </v-list-item>
          </NuxtLink>
        </template>
      </v-list>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
export default {
  setup () {

    // 글로벌 스토어
    let $i18n: any = ref(globalVariables.app.i18n)
    
    const languages: any = ref([
      { type: 'subheader', title: 'LANGUAGE' }
    ])
    globalVariables.language.forEach(language => {
      languages.value.push({
        text: language.text,
        code: language.code,
        event: () => $i18n.value.$changei18n(language.code)
      })
    })

    // 메모리 비우기
    function restore () {
      $i18n.value = null
      languages.value = null
    }

    onBeforeUnmount (() => {
      restore()
    })

    return {
      languages
    }
  },
  props: {
    fsize: {
      type: String,
      default () {
        return ''
      }
    },
    flag: {
      type: Number,
      default () {
        return 20
      }
    }
  }
}
</script>